.nav-item .nav-link:hover,
.nav-item .nav-link:focus,
.nav-item.show .nav-link {
  background-color: $background-nav;
}

@media (min-width: $customMobileBreak) {
  nav[role="navigation"] .header .navbar-header.brand .logo-home img {
    width: 160px;
  }
}

.edit-profile-form {
  .row {
    .col {
      .edit-profile {
        &-cancel {
          &:hover {
            box-shadow: none !important;
          }
        }

        &-save {
          background-color: #5b2e82;

          &:hover {
            box-shadow: none !important;
          }
        }
      }
    }

    .birth-edit {
      font-size: 14px;
      margin-bottom: 0;
      color: #999;

      .required-asterisk {
        color: #999 !important;
      }
    }
  }
}

button,
input[type="button"],
input[type="submit"],
input[type="reset"],
input[type="password"],
input[type="text"] {
  box-shadow: none !important;
}

.controls-container {
  button {
    background-color: #5b2e82 !important;
  }
}

button:focus,
input:focus,
select:focus,
textarea:focus {
  outline: none !important;
}

.brand {
  img {
    width: 80%;
  }
}

.error500 {
  hr {
    display: none;
  }
}

header ~ #maincontent {
  .container.product-detail {
    div.cart-and-ipay {
      .add-to-cart {
        border-color: $secondary-color-1;
      }
    }
  }
}

nav[role="navigation"] {
  border: 0.5px solid $menu-line;

  .main-menu {
    background-color: $background-nav;
    border-bottom: 0;

    .container {
      @include respond-to-max-width($max-tablet-width) {
        margin-left: 0;
      }
    }
  }

  .navbar-header {
    &.brand {
      @include respond-to-min-width(992px) {
        .logo-home {
          top: 0%;
        }
      }
    }
  }

  .nav-item .nav-link:focus,
  .nav-item .nav-link:hover,
  .nav-item.show .nav-link {
    background-color: $background-nav;
  }

  @include respond-to-max-width($max-tablet-width) {
    .nav-item.dropdown {
      .menu-items-container {
        .options-container {
          .dropdown-menu {
            .dropdown-item {
              border-bottom: 1px solid $menu-line;
            }
          }
        }
      }
    }
  }

  @include respond-to-min-width($max-mobile-width) {
    .header .navbar-header.brand .logo-home img {
      width: 120px;
      top: 0%;

      @include respond-to-min-width(1340px) {
        width: 100px !important;
        transform: scale(0.8);
      }
    }
  }

  @include respond-to-max-width($max-mobile-width) {
    .header .navbar-header.brand {
      top: 40%;
    }

    .multilevel-dropdown .navbar > .close-menu div.back.pull-left img {
      height: 40px;
    }

    .main-menu.multilevel-dropdown .close-menu .back img {
      height: 35px;
      width: 100%;
      margin-top: 5%;
    }
  }
}

.modal-dialog.quick-view-dialog {
  .modal-content {
    .modal-body {
      .button-container button {
        border-color: $secondary-color-1;
      }
    }
  }
}

.header-banner {
  color: $primary-color-2;
}

.header-banner.checkout {
  .container {
    .close-button {
      .close {
        background-color: $promotional-banner;
        color: $promotional-banner;
      }
    }
  }
}

.card-body {
  font-family: $main-font;
}

.form-control-label {
  font-family: $main-font;
}

.infobar {
  width: 100%;
  background-color: #fff;
  height: 37px;
  display: inline-flex;
  border-bottom: solid 1px #979797;
  justify-content: flex-end;

  &-attcli {
    font-weight: bold;
    margin: 0;
    padding: 0;
  }

  div {
    height: 19px;
    margin-top: 7px;
    padding: 0 8px;
    text-align: left;
    font: normal normal normal 14px/22px Roboto;
    letter-spacing: 0;
    color: #121212;
    opacity: 1;
    border-right: 1px solid;

    a {
      color: #121212;
      text-decoration: none;
    }

    &:last-child {
      border-right: 0;
      margin-right: 40px;
    }
  }

  hr {
    display: none;
  }

  @include respond-to-max-width($max-mobile-width) {
    height: 159px;
    display: table-cell;
    vertical-align: middle;
    width: 768px;

    div {
      border-right: 0;
      margin: 10px 0 10px 20px;
      padding: 0;

      &:last-child {
        margin-bottom: 30px;
      }
    }

    hr {
      display: block;
      margin: 0 20px;
      width: calc(100% - 40px);
    }

    .fa-envelope-o {
      display: none;
    }
  }
}

.minicart {
  &-quantity {
    background-color: $black-text;
  }
}

.site-search {
  .fa-close,
  .fa-search,
  .reset-button {
    color: $primary-color-1;
  }
}

.header__nav-redirect-moov,
.header__nav-redirect-dexter {
  display: none;
}

footer {
  &__logo {
    width: 90px;
  }
}

.account-payment-methods,
.payments,
.address-book,
.addresses {
  .add-new {
    a {
      padding: 15px 20px;
      font-size: 15px;
      border: none;
      outline: 0;
      height: 50px;
      display: block;
      margin: 0 auto;
      font-weight: 700;
      background-color: $secondary-color-1;
      border-color: $secondary-color-1;
      border-radius: 25px;
      color: #fff;
      text-transform: uppercase;
      font-family: 'Roboto';

      &:hover {
        background-color: $secondary-color-1;
      }

      &:focus {
        background-color: $secondary-color-1;
        box-shadow: none !important;
      }

      &:active {
        background-color: $secondary-color-1 !important;
        border-color: $secondary-color-1 !important;
      }
    }
  }
}

.account-payment-methods,
.payments {
  .card-body .payment-form button.btn-save {
    background-color: #5b2e82;

    &:hover {
      background-color: #5b2e82;
    }
  }

  .add-new {
    a {
      background-color: $primary-color-1;
      border-color: $primary-color-1;

      &:hover {
        background-color: $primary-color-1;
      }

      &:focus {
        background: #c11a7d !important;
        box-shadow: none !important;
      }
    }
  }
}

.store-locator-container .select-store {
  background-color: #5b2e82;
  cursor: pointer;

  &:active {
    background-color: #5b2e82;
  }
}

.btn-primary {
  background-color: #5b2e82;
  border-color: #5b2e82;
}

.select-store-correo-arg {
  border-radius: 25px;
  opacity: 1;
  background: #5b2e82;
  border: none;
  height: 50px;
  width: 100%;
  font-family: $main-font;
  cursor: pointer;

  &:active {
    background: #5b2e82;
  }

  &:focus {
    outline: none;
  }

  &.btn-primary {
    @include respond-to-min-width(544px) {
      margin-top: 1rem;
    }
  }
}

@include respond-to-max-width(543.98px) {
  #home {
    #recommendations {
      .carousel.indicators-xs {
        padding-bottom: 0;
      }
    }
  }
}

@include respond-to-min-width(1341px) {
  .header.container.header__container .container-insert-postal {
    color: #fff;
  }
}
